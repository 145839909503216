var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-home"},[_c('section',{attrs:{"id":"home-project-section"}},[_c('CarrouselImages')],1),_c('Overlay'),_c('div',{staticClass:"container-section-fast-access"},[_c('div',{staticClass:"col-card-fast"},[_c('div',{staticClass:"container-card-fast"},[_c('v-btn',{staticClass:"fvl-btn green-primary mb-2 mr-2",attrs:{"elevation":"1","disabled":""}},[_vm._v(" Accesos Rápidos ")])],1),_c('router-link',{staticClass:"link-subitem",attrs:{"to":_vm.links.profile.detail}},[_c('div',{staticClass:"container-card-fast"},[_c('v-btn',{staticClass:"fvl-btn transparent-secundary",attrs:{"elevation":"1"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("assignment_ind")]),_vm._v("Mi Cuenta ")],1)],1)])],1),_c('div',{staticClass:"row-card-fast"},[(
          _vm.user.permissions.indexOf(_vm.permissionsAll.user_list) >= 0 ||
            _vm.user.person.types_person.id === 3
        )?_c('router-link',{staticClass:"link-subitem",attrs:{"to":_vm.links.user.list}},[_c('div',{staticClass:"container-card-fast"},[_c('v-btn',{staticClass:"fvl-btn transparent-secundary",attrs:{"elevation":"1"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("group")]),_vm._v("Listado de Usuarios ")],1)],1)]):_vm._e(),(
          _vm.user.permissions.indexOf(_vm.permissionsAll.user_create) >= 0 ||
            _vm.user.person.types_person.id === 3
        )?_c('router-link',{staticClass:"link-subitem",attrs:{"to":_vm.links.user.create}},[_c('div',{staticClass:"container-card-fast"},[_c('v-btn',{staticClass:"fvl-btn transparent-secundary",attrs:{"elevation":"1"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("person_add")]),_vm._v("Añadir Usuario ")],1)],1)]):_vm._e(),(
          _vm.user.permissions.indexOf(_vm.permissionsAll.project_create) >= 0 ||
            _vm.user.person.types_person.id === 3
        )?_c('router-link',{staticClass:"link-subitem",attrs:{"to":_vm.links.project.create}},[_c('div',{staticClass:"container-card-fast"},[_c('v-btn',{staticClass:"fvl-btn transparent-secundary",attrs:{"elevation":"1"}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("add_box")]),_vm._v("Crear Proyecto ")],1)],1)]):_vm._e()],1),_c('div',{staticClass:"col-card-fast"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }