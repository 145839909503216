<template lang="">
  <div class="container-home">
    <!--PROJECTS SLIDER -->
    <section id="home-project-section">
       <CarrouselImages />
       <!-- <CarrouselProjects /> -->
    </section>

  
    <Overlay />
 
  <div class="container-section-fast-access">
   <!-- ACCESOS RAPIDOS -->
    <div class="col-card-fast">
        <!-- 1 -->
         <div class="container-card-fast">
              <v-btn
              class="fvl-btn green-primary mb-2 mr-2"
              elevation="1"
              disabled
              >
              Accesos Rápidos
              </v-btn>
              </div>
        <router-link class="link-subitem" :to="links.profile.detail">
          <div class="container-card-fast">
            <v-btn
              class="fvl-btn transparent-secundary"
              elevation="1"
            >
            <v-icon small left>assignment_ind</v-icon>Mi Cuenta
            </v-btn>
          </div>
        </router-link>
        <!-- c1 -->
      </div>
        <!-- PANEL DE USUARIOS -->
      <div class="row-card-fast">
         <!-- c2 -->
          <router-link
          class="link-subitem"
          :to="links.user.list"
          v-if="
            user.permissions.indexOf(permissionsAll.user_list) >= 0 ||
              user.person.types_person.id === 3
          "
          >
          <div class="container-card-fast">
            <v-btn
                class="fvl-btn transparent-secundary"
                elevation="1"
                
                >
                 <v-icon small left>group</v-icon>Listado de Usuarios
              </v-btn>
           
          </div>
        </router-link>
        <!-- c2 -->
        <!-- c3 -->
           <router-link
          class="link-subitem"
          :to="links.user.create"
          v-if="
            user.permissions.indexOf(permissionsAll.user_create) >= 0 ||
              user.person.types_person.id === 3
          "
          >
            <div class="container-card-fast">
               <v-btn
              class="fvl-btn transparent-secundary"
              elevation="1"
              
            >
              <v-icon small left>person_add</v-icon>Añadir Usuario
            </v-btn>
            </div>
        </router-link>
        <!-- c3 -->
        <!-- c4-->
        <router-link
          class="link-subitem"
          :to="links.project.create"
          v-if="
            user.permissions.indexOf(permissionsAll.project_create) >= 0 ||
              user.person.types_person.id === 3
          "
        >
          <div class="container-card-fast">
            <v-btn
              class="fvl-btn transparent-secundary"
              elevation="1"
            >
              <v-icon small left>add_box</v-icon>Crear Proyecto
            </v-btn>
          </div>
        </router-link>
        <!-- c4-->
       
      </div>
      <!-- PANEL DE PROYECTOS -->
      <div class="col-card-fast">
         
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { beginRequest, endRequest } from "../helpers";
import CarrouselImages from "../components/project/CarrouselImages.vue";
import CarrouselProjects from "../components/project/CarrouselProjects.vue";
import { Overlay } from "../components/_commons";
import links from "../helpers/links";
import permissions from "../helpers/permissions";

export default {
  name: "Home",
  data() {
    return {
      links: links,
      permissionsAll: permissions,
    };
  },
  components: { CarrouselImages, CarrouselProjects, Overlay },
  computed: {
    ...mapState(["user"]),
  },
  async created() {
    await beginRequest();
    let result = null;
    if (
      this.user.person.types_person.id === 3 ||
      this.user.person.types_person.id === 1
    ) {
      // SI EL USUARIO ES ADMIN O SUPER ADMIN VIENEN TODOS LOS PROYECTOS.
      result = await this.$store.dispatch("getProjects");
    } else {
      // CASO CONTRARIO (USUARIO != DE ADMIN O SUPERADMIN) SOLO PUEDE VER LOS PROYECTOS EN LOS QUE ESTÁ ASOCIADO
      result = await this.$store.dispatch(
        "getProjectsByUser",
        this.user.person.id
      );
    }
    console.log(result);
    const { status, snack } = result;
    await endRequest(snack, status);
  },
};
</script>
