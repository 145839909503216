<template lang="">
  <div>
    <!-- Header Project Section -->
    <div class="container-header-carousel">
      <span class="home-title-section">Mis Proyectos OWL</span>
      <div >
      <v-text-field
        v-model="nameProject"
        label="Buscar..."
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    </div>
    

<!-- Header Project Section -->

<carousel>
  <li
    class="item"
     v-for="(project, index) in getProjectsByNameAction"
  ></li>
</carousel>

  </div>
</template>
<script>


import carousel from "vue-owl-carousel";
import { links } from "../../helpers";
import { mapGetters } from "vuex";
import router from "../../router";




export default {
  name: "CarouselProjects",
  components: { carousel },
  data() {
    return {
      nameProject: "",
    };
  },
  methods: {
    getUrlImg(image) {
      const url = image
        ? `${process.env.VUE_APP_SERVER}${image}`
        : "../../assets/img/item1.png";
      return url;
    },
    async viewDetail(item) {
      await this.$store.dispatch("setProjectSelectedAction", null);
      router.push(`${links.project.detail}/${item.project.id}`);
    },
  },
  computed: {
    ...mapGetters(["getProjectsByName"]),
    getProjectsByNameAction() {
      return this.getProjectsByName(this.nameProject);
    },
  },
};
</script>
<style lang=""></style>
